const phoneToInt = phone => {
    const array = phone.split("");

    array.splice(0, 4);
    array.splice(3, 2);
    array.splice(6, 1);

    const string = array.join("");
    
    return string;
};

export default phoneToInt