import React from 'react'
import { Link } from 'react-router-dom'

const MainHeader = () => {
    return (
        <div className='main-header'>
            <Link to="/" style={{ marginLeft: "1em", color: "black", textDecoration: "none" }}>
                <p>
                    Get My Signature
                </p>
            </Link>
            <Link to="updateSignature" style={{ marginRight: "1em", color: "black", textDecoration: "none" }}>
                <p>
                    Activate/Update My Signature
                </p>
            </Link>
            <Link to="/uploadQuarterly" style={{ marginRight: "1em", color: "black", textDecoration: "none" }}>
                <p> 
                    Upload Image for quarter
                </p>
            </Link>
        </div>
    )
}
 
export default MainHeader