import { useState, useEffect } from 'react'
import requestURL from '../../utilities/requestURL';

const ProtectUpload = ({ children }) => {

    const [approved, setApproved] = useState("loading");

    const fetchAuthorization = async () => {
        try {
            const res = await fetch(`${requestURL}user/authorizeView`, {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include" 
            });

            const data = await res.json();

            if (data.message === 0) {
                setApproved(true)
            } else if (data.message === 1) {
                setApproved(false);
                alert("There was a server error authenticating you")
            } else if (data.message === 2) {
                setApproved(false);
            } else {
                alert("There was a weird unexpected error that. Contact IT autobots");
                setApproved(false);
            };
        } catch (error) {
           console.log(error);
           alert("Front end error"); 
        }
    };

    useEffect(() => {
        const getAuthorization = async () => {
            await fetchAuthorization();
        }

        getAuthorization();
    }, []);

    if (approved === true) {
        return children;
    } else if (approved === "loading") {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <h4>
                    Loading...
                </h4>
            </div>
        )
    } else {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10em'}}>
                <p>
                    You do not have access to upload a quarterly photo
                </p>
            </div>
        )
    }
}

export default ProtectUpload