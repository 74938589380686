const convertToPhone = (phoneNumber) => {

    let phoneArr = phoneNumber.split("");

    phoneArr.splice(0, 0, "+");

    phoneArr.splice(2, 0, " (");

    phoneArr.splice(6, 0, ") ");

    phoneArr.splice(10, 0, "-");

    const num = phoneArr.join("");

    return num
};

export default convertToPhone