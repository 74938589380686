import { v4 as uuidv4 } from 'uuid';
import requestURL from '../../utilities/requestURL';

const Signature = ({ name, title, email, phone, other, picture }) => {

    return (
        <div style={{ marginTop: '10em', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '85%',
            marginBottom: '5em' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: '25em' }}>
                <div style={{ marginRight: '2em' }}>
                    <img src={picture} alt="profile pic" style={{ height: '112px', width: '112px', 
                        borderRadius: '50%' }} />
                </div>
                <div>
                    <p style={{ fontSize: '.85em', marginBottom: '0em', marginTop: '0em' }}>
                        <b>{name}</b>
                    </p>
                    <p style={{ fontSize: '.85em', marginBottom: '0em', marginTop: '0em' }}>
                        <em>{title}</em>
                    </p>
                    <p style={{ fontSize: '.85em', marginBottom: '0em', marginTop: '0em' }}>
                        eAssist Dental Solutions
                    </p>
                    <p style={{ fontSize: '.85em', marginBottom: '0em', marginTop: '0em' }}>
                        {email}
                    </p>
                    <p style={{ fontSize: '.85em', marginBottom: '0em', marginTop: '0em' }}>
                        {phone} 
                    </p>
                    {other.map((other) => 
                        <p style={{ fontSize: '.85em', marginBottom: '0em', marginTop: '0em' }} key={uuidv4()}>
                            {other}
                        </p>
                    )}
                    <div style={{ height: ".8em" }} />
                    <div>
                        <a href="https://www.facebook.com/eAssistMe/" target="_blank" rel="noreferrer">
                            <img src='https://signature-server.eassist.me/photo/facebookLogo'
                            alt="facebook" style={{ marginRight: '.5px' }} />
                        </a>
                        <a href="https://www.linkedin.com/company/eassist" target="_blank" rel="noreferrer">
                            <img src="https://signature-server.eassist.me/photo/linkedinLogo" 
                            alt="linkedin" style={{ marginRight: '.5px' }} />
                        </a>
                        <a href="https://www.instagram.com/eassist.me/" target="_blank" rel="noreferrer">
                            <img src="https://signature-server.eassist.me/photo/instagramLogo" 
                            alt="instagram" style={{ marginRight: '.5px' }} />
                        </a>
                        <a href="https://twitter.com/eassistme" target="_blank" rel="noreferrer">
                            <img src="https://signature-server.eassist.me/photo/twitterLogo"
                            alt="twiiter" style={{ marginRight: '.5px' }} />
                        </a>
                        <a href="https://www.youtube.com/channel/UCKYpQs29a-N6MavL4P9fr4A?reload=9" target="_blank" rel="noreferrer">
                            <img src="https://signature-server.eassist.me/photo/youtubeLogo"
                            alt="youtube" style={{ marginRight: '.5px' }} />
                        </a>
                    </div>
                </div>
            </div>
            <div style={{ height: "12px" }} />
            <img src={`${requestURL}photo/currentQuarter`} alt="eassist logo" style={{ width: '400px' }} />
            <p style={{ fontSize: '12px', width: '800px' }}>
                <em>
                    This email, including any attachments, is confidential, may be legally privileged, and is intended solely for the named recipient(s). If you received this email in error, please notify the sender immediately, destroy any hard copy, and permanently delete this email and any attachments from your system. If you are not the intended recipient, your access to this email is unauthorized, and any disclosure, copying, distribution, or use of the contents of this email, including any attachments, for any reason is strictly prohibited. Emails may contain viruses, worms or other malicious code, and the recipient accepts full responsibility for taking protective action against all of the foregoing.  eAssist is not liable for any damage or loss arising from this email, including any attachments.  If you have received this email in error, or would like to opt out of future marketing emails, please reply immediately to the sender. Thank you. 665 West 750 North, American Fork, UT 84003
                </em>
            </p>
        </div>
    )
}

export default Signature
