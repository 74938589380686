import { useState, useEffect } from 'react'
import { FaWindowClose } from 'react-icons/fa';
import {v4 as uuidv4} from 'uuid';
import requestURL from '../../utilities/requestURL';
import convertToPhone from '../../utilities/convertToPhone'; 
import phoneToInt from '../../utilities/phoneToInt';

const UpdateSignature = () => {

    const email = JSON.parse(sessionStorage.getItem("email"));

    const [name, setName] = useState("");
    const [title, setTitle] = useState("");
    const [firstNumber, setFirstNumber] = useState("");
    const [secondNumber, setSecondNumber] = useState("");
    const [thirdNumber, setThirdNumber] = useState("");
    const [fourthNumber, setFourthNumber] = useState("");
    const [fifthNumber, setFifthNumber] = useState("");
    const [sixthNumber, setSixthNumber] = useState("");
    const [seventhNumber, setSeventhNumber] = useState("");
    const [eighthNumber, setEighthNumber] = useState("");
    const [ninthNumber, setNinthNumber] = useState("");
    const [tenthNumber, setTenthNumber] = useState("");
    const [phone, setPhone] = useState("");
    const [other, setOther] = useState([]); 
    const [newPhrase, setNewPhrase] = useState("");
    const [isActive, setIsActive] = useState(false);

    const fetchInfo = async () => {

        try {
            const res = await fetch(`${requestURL}user/userInfo`, {
                headers: {
                    "email": email
                },
                credentials: "include"
            });

            const data = await res.json();

            if (data.message === 0) {
                setName(data.user.name);
                setTitle(data.user.title);
                setPhone(data.user.phone);
                setOther(data.user.other);
                setIsActive(data.user.isActive);

                const phoneInt = phoneToInt(data.user.phone);

                setFirstNumber(phoneInt[0]);
                setSecondNumber(phoneInt[1]);
                setThirdNumber(phoneInt[2]);
                setFourthNumber(phoneInt[3]);
                setFifthNumber(phoneInt[4]);
                setSixthNumber(phoneInt[5]);
                setSeventhNumber(phoneInt[6]);
                setEighthNumber(phoneInt[7]);
                setNinthNumber(phoneInt[8]);
                setTenthNumber(phoneInt[9]);
            } else if (data.message === 1) {
                alert("Server error :( Could not load your signature data");
            } else if (data.message === 2) {
                setName(data.user.name);
            } else if (data.message === 3) {
                alert("A user with your email was not found in the database. Try logging out and logging back in");
            } else {
                alert("There was a weird unexpected error that stopped your data from being loaded");
            }
        } catch (error) {
            alert("There was a front end error that stopped the info from being loaded");
            console.log(error);
        }
    };

    useEffect(() => {
        const getInfo = async () => {
            await fetchInfo();
        };

        getInfo();
    }, []);

    const handleAddPhrase = (e) => {
        if (e.key === "Enter" && newPhrase !== "") {
            setOther([...other, newPhrase]);
            setNewPhrase("");
        }
    };

    const handleRemovePhrase = (toRemove) => {
        setOther(other.filter((phrase) => phrase !== toRemove))
    };

    const handleUpdate = async () => {
        try {
            if (name === "" || title === "" || phone === "") {
                alert("All fields must have a value (except 'other')")
            } else {
                const res = await fetch(`${requestURL}user/updateSignature`, {
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/json"
                    }, 
                    body: JSON.stringify({
                        email: email,
                        title: title,
                        phone: phone,
                        other: other,
                        name: name
                    })
                });

                const data = await res.json();

                if (data.message === 0) {
                    alert("You're signature has been successfully updated!");
                    window.location.href="/";
                } else if (data.message === 1) {
                    alert("There was a server error that prevented the signature update :(");
                } else {
                    alert("There was a weird unexpected error");
                }
            }
        } catch (error) {
            console.log(error);
            alert("There was a front end error");
        }
    };

    const handleMoveNext = e => {
        const { value, id } = e.target; 

        if (id === "1") {
            setFirstNumber(value)
        } else if (id === "2") {
            setSecondNumber(value)
        } else if (id === "3") {
            setThirdNumber(value)
        } else if (id === "4") {
            setFourthNumber(value)
        } else if (id === "5") {
            setFifthNumber(value)
        } else if (id === "6") {
            setSixthNumber(value)
        } else if (id === "7") {
            setSeventhNumber(value)
        } else if (id === "8") {
            setEighthNumber(value)
        } else if (id === "9") {
            setNinthNumber(value)
        } else if (id === "10") {
            setTenthNumber(value);
        };
 
        if (value.length >= 1) {
            const next = parseInt(id) + 1;

            const nextSibling = document.getElementById(next);

            if (nextSibling) {
                nextSibling.focus();
            }
        };
    };

    useEffect(() => {
        const formatedNumber = convertToPhone("1"+firstNumber+secondNumber+thirdNumber+fourthNumber+fifthNumber+sixthNumber
        +seventhNumber+eighthNumber+ninthNumber+tenthNumber);

        setPhone(formatedNumber);
    }, [firstNumber, secondNumber, thirdNumber, fourthNumber, fifthNumber, sixthNumber, seventhNumber, eighthNumber,
    ninthNumber, tenthNumber]);
 
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        marginTop: '5em' }}>
            <h4>
                {isActive ?
                    "Update your signature"
                    :
                    "Activate your signature"
                }
            </h4>
            <p>
                (Enter info exactly as you want it to appear on your signature)
            </p>
            <p>
                First and Last Name
            </p>
            <input placeholder='First and Last Name' className='create-input' onChange={e => setName(e.target.value)} 
            value={name} />
            <p>
                Position in eAssist (ex: Success Consultant)
            </p>
            <input placeholder='Title' className='create-input' onChange={e => setTitle(e.target.value)} 
            value={title} />
            <p>
                Phone number
            </p>
            <div style={{ display: "flex", width: "50%", alignItems: "center", justifyContent: "center", marginBottom: "1em" }}>
                <p style={{ fontSize: "1.5em", height: "2em", marginTop: "1em" }}>
                    (&nbsp;
                </p>
                <input type="text" maxLength={1} id="1" className='phone-input' onChange={handleMoveNext} value={firstNumber} />
                <input type="text" maxLength={1} id="2" className='phone-input' onChange={handleMoveNext} value={secondNumber} />
                <input type="text" maxLength={1} id="3"  className='phone-input' onChange={handleMoveNext} value={thirdNumber} />
                <p style={{ fontSize: "1.5em", height: "2em", marginTop: "1em" }}>
                    )&nbsp;
                </p>
                <input type="text" maxLength={1} id="4" className='phone-input' onChange={handleMoveNext} value={fourthNumber} />
                <input type="text" maxLength={1} id="5" className='phone-input' onChange={handleMoveNext} value={fifthNumber} />
                <input type="text" maxLength={1} id="6" className='phone-input' onChange={handleMoveNext} value={sixthNumber} />
                <p style={{ fontSize: "1.5em", height: "2em", marginTop: "1em" }}>
                    -&nbsp;
                </p>
                <input type="text" maxLength={1} id="7" className='phone-input' onChange={handleMoveNext} value={seventhNumber} />
                <input type="text" maxLength={1} id="8" className='phone-input' onChange={handleMoveNext} value={eighthNumber} />
                <input type="text" maxLength={1} id="9" className='phone-input' onChange={handleMoveNext} value={ninthNumber} />
                <input type="text" maxLength={1} id="10" className='phone-input' onChange={handleMoveNext} value={tenthNumber} />
            </div>
            <p>
                Calendly
            </p>
            <input placeholder='Type a phrase and press enter to add it to your signature' className='create-input' 
                onChange={e => setNewPhrase(e.target.value)} onKeyDown={handleAddPhrase} value={newPhrase} />
            <div style={{ width: '50%' }}>
                <p>
                    Your phrases: {other.length === 0 ? "no phrases added" : null}
                </p>
                    {other.map((phrase) => 
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} key={uuidv4()}>
                            <p>
                                {phrase}
                            </p>
                            <FaWindowClose onClick={() => handleRemovePhrase(phrase)} style={{ cursor: 'pointer', color: 'red'}} />
                        </div>
                    )}
            </div>
            <button className='btn btn-primary btn-block' style={{ marginBottom: "3em" }} onClick={() => handleUpdate()}>
                {isActive ? "Make Changes" : "Activate Signature"}
            </button>
        </div>
    )
}

export default UpdateSignature