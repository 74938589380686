import { useEffect } from 'react'
import jwt_decode from 'jwt-decode';
import requestURL from '../../utilities/requestURL';
import setLogin from '../../utilities/setLogin';

const Login = () => { 

    const handleCallbackResponse = async (response) => {
        const rawToken = response.credential;

        const user = jwt_decode(rawToken);

        try {
            const res = await fetch(`${requestURL}user/login`, {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json"
                }, 
                body: JSON.stringify({
                    email: user.email,
                    name: user.given_name + " " + user.family_name,
                    picture: user.picture,
                    token: rawToken
                }),
                credentials: "include"
            });
    
            const data = await res.json();
    
            if (data.message === 0) {
                setLogin(user.email, rawToken);
                window.location.reload();
            } else if (data.message === 1) {
                alert("There was a server error completing the login. Contact IT autobots");
            } else {
                console.log(data);
                alert("there was a weird unexpected error");
            }; 
        } catch (error) {
            console.log(error);
            alert("There was a frontend error that stopped the login process");
        }
    };

    useEffect(() => {
        /*global google*/
        google.accounts.id.initialize({
            client_id: "990174122929-71vp8m8cg5lhc7mp71ial144d5t6s3kg.apps.googleusercontent.com",
            callback: handleCallbackResponse
          });
      
          google.accounts.id.renderButton(
            document.getElementById('signInDiv'),
            { theme: 'outline', size: 'large' } 
          );
    }, []);

    return (
        <>  
            <div className='main-header' style={{ justifyContent: 'center' }}>
                <h2>
                    eAssist Email Signatures
                </h2>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '40em',
                alignItems: 'center', justifyContent: 'center' }}>
                <h3 style={{ marginBottom: "2em" }}>
                Sign in with Google to continue
                </h3>
                <div id="signInDiv" />
            </div>
        </>
    )
}

export default Login