import { useState } from 'react';
import Signature from './Signature.js';
import requestURL from '../../utilities/requestURL';

const GetSignature = () => {

    const email = JSON.parse(sessionStorage.getItem("email"));

    const [showSignature, setShowSignature] = useState(false);
    const [title, setTitle] = useState(null);
    const [name, setName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [other, setOther] = useState([]);
    const [picture, setPicture] = useState(null);
    const [message, setMessage] = useState(null);

    const fetchData = async () => {
        try {
            setMessage("Loading...")

            const res = await fetch(`${requestURL}user/userInfo`, {
                headers: {
                    "email": email
                },
                credentials: "include"
            });

            const data = await res.json();

            if (data.message === 0) {
                setTitle(data.user.title);
                setPhone(data.user.phone);
                setName(data.user.name);
                setPicture(data.user.picture);
                setOther(data.user.other);
                setMessage(`To set your signature go to your eassist gmail and click the settings gear, then click 
                'See all settings'. Scroll down to where it says signature, then copy and paste the signature below
                in place of your current signature.`);
                setShowSignature(true);
            } else if (data.message === 1) { 
                setMessage("There was a server error :( Try again later")
            } else if (data.message === 2) {
                setMessage("You're signature is not yet active. Go to activate/update signature to activate it")
            } else if (data.message === 3) {
                setMessage("You're email was not found in the database. Contact IT autobots");
            } else {
                setMessage("There was a weird error. Contact IT autobots")
            }
        } catch (error) {
            console.log(error);
            alert("Front end error prevented operation");
            setMessage(null); 
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        marginTop: '5em' }}>
            <h4 style={{ marginBottom: '1em' }}>
                Press the button to get this quarter's signature for {email}
            </h4>
            <p>
                Need help? &nbsp;
                <a href="https://docs.google.com/document/d/1JJNxzf17bflh-zsxfxvcoxR47xmRp2omyHB2W1xmJoQ" target="_blank">
                    Click here
                </a>
                &nbsp; for a guide on using the signature generator
            </p>
            <button className='btn btn-primary btn-block' onClick={() => fetchData()}>
                Get Signature
            </button>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "3em", width: '80%' }}>
                <p>
                    {message}
                </p>
            </div>
            {showSignature ?
                <Signature name={name} title={title} email={email} phone={phone} other={other} picture={picture} />
                :
                null
            }
        </div>
    )
}

export default GetSignature
