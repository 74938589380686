import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import Login from './components/login/Login';
import MainHeader from './components/header/MainHeader';
import GetSignature from './components/getSignature/GetSignature';
import UpdateSignature from './components/updateSignature/UpdateSignature';
import Upload from './components/uploadQuarterly/Upload';
import ProtectUpload from './components/uploadQuarterly/ProtectUpload';

function App() {

  const email = JSON.parse(sessionStorage.getItem("email"));
  const token = JSON.parse(sessionStorage.getItem("token"));

  if (token && email) {
    return (
      <div>
        <Router>
          <MainHeader />
          <Routes>
            <Route path="/*" element={<GetSignature />} />
            <Route path="/updateSignature" element={<UpdateSignature />} />
            <Route path="/uploadQuarterly" element={<ProtectUpload> <Upload /> </ProtectUpload>} />
          </Routes>
        </Router>
      </div>
    )
  } else {
    return (
      <div>
        <Login />
      </div>
    );
  }
}

export default App;
