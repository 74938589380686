import { useState } from 'react';
import axios from 'axios';
import requestURL from '../../utilities/requestURL';

const Upload = () => {

    const [image, setImage] = useState(null);
    const [quarter, setQuarter] = useState(null);
    const [message, setMessage] = useState(null);

    const handleFileSelection = (e) => {
        setImage(e.target.files[0]);
    }; 

    const handleUpdatePicture = async () => {
        try {
            setMessage("Loading...");

            const fileData = new FormData();

            fileData.append('image', image);

            const res = await axios.post(`${requestURL}photo/uploadQuarterly`, fileData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "quarter": quarter
                },
                withCredentials: true
            });

            const data = await res.data;

            if (data.message === 0) {
                setMessage(null);
                alert("Picture successfully updated!");
                window.location.href="/";
            } else if (data.message === 1) {
                setMessage(null);
                alert("Server error prevented picture from being updated");
            } else {
                setMessage(null);
                alert("Weird unrecognized response");
            }
        } catch (error) {
            console.log(error);
            alert("Front end error");
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <h4 style={{ marginBottom: "2em" }}>
                Upload New Quarterly Picture
            </h4>
            <p>
                Enter the name of the quarter that this signature is for
            </p>
            <input placeholder='Quarter' className='create-input' onChange={e => setQuarter(e.target.value)} />
            <div className="input-group mb-3" style={{ width: '50%' }}> 
                <input type="file" className="form-control" id="inputGroupFile01" onChange={handleFileSelection}
                accept="image/png, image/gif, image/jpeg" />
            </div>
            <p>
                Must be jpeg, jpg, or png
            </p>
            <button className='btn btn-primary btn-block' onClick={() => handleUpdatePicture()}>
                Update picture
            </button>
            {message ? 
                <p>
                    {message}
                </p>
                :
                null
            }
        </div>
    )
}

export default Upload